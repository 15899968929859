import React from "react"

import background from '../../../../../assets/img/stays/thailand/bangkok/city.jpg'

const stay = {
  id: 'thailand-bangkok-platinium',
  slug: 'thailand-bangkok-platinium',
  name: 'Bangkok Platinium',
  description: '',
  descriptionHead: <span></span>,
  descriptionLong: '',
  url: '/stay/thailand/bangkok/platinium',
  images: {
    background: background,
    activities: [
    ],
    hotel: [
    ],
    room: {
      double: '',
      twin: ''
    },
    resume: [//common
    ]
  },
  checkLists: [
  ],
  program: [
  ],
  infos: [
  ],
  faq: [
  ],
  place: {
    name: '',
    map: <iframe title="" src=""></iframe>
  },
  datas: {
    stars: 5,
    category: 'platinium',
    starsName: 'Hôtel 5 étoiles',
    price: 2360,
    days: 10,
    nights: 9,
    comingsoon: true
  }
}

export default stay