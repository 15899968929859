import thailand from '../../../assets/img/icons/countries/svg/thailand.svg'

import bangkok from './bangkok'
import phuket from './phuket'
import kosamui from './kosamui'

const country = {
  id: 'thailand',
  slug: 'thailand',
  name: 'Thaïlande',
  images: {
    flag: thailand
  },
  cities: [
    phuket,
    bangkok,
    kosamui
  ]
}

export default country