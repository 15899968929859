import React from "react"

import background from '../../../../../assets/img/stays/thailand/kosamui/city.jpg'

const stay = {
  id: 'thailand-kosamui-gold',
  slug: 'thailand-kosamui-gold',
  name: 'Ko Samui Gold',
  description: '',
  descriptionHead: <span></span>,
  descriptionLong: '',
  url: '/stay/thailand/kosamui/gold',
  images: {
    background: background,
    activities: [
    ],
    hotel: [
    ],
    room: {
      double: '',
      twin: ''
    },
    resume: [//common
    ]
  },
  checkLists: [
  ],
  program: [
  ],
  infos: [
  ],
  faq: [
  ],
  place: {
    name: '',
    map: <iframe title="" src=""></iframe>
  },
  datas: {
    stars: 3,
    category: 'gold',
    starsName: 'Hôtel 3 étoiles avec plage',
    price: 1790,
    days: 10,
    nights: 9,
    comingsoon: true
  }
}

export default stay