import kosamui from '../../../../assets/img/stays/thailand/kosamui/city.jpg'

import gold from './gold'

const city = {
  id: 'thailand-kosamui',
  slug: 'thailand-kosamui',
  name: 'Ko Samui',
  description: "Ko Samui est la deuxième plus grande île de Thaïlande. Elle se situe dans le golfe de Thaïlande, au large de la côte est de l'isthme de Kra. Elle est réputée pour ses plages bordées de palmiers, ses cocoteraies, sa forêt tropicale dense et montagneuse, ses complexes de luxe ainsi que ses spas huppés. Le célèbre temple Wat Phra Yai, avec sa statue de Bouddha dorée de 12 mètres de hauteur, occupe une petite île reliée à Ko Samui par une chaussée surélevée.",
  images: {
    background: kosamui
  },
  stays: [
    gold
  ],
  comingsoon: true
}

export default city