import bangkok from '../../../../assets/img/stays/thailand/bangkok/city.jpg'

import gold from './gold'
import platinium from './platinium'

const city = {
  id: 'thailand-bangkok',
  slug: 'thailand-bangkok',
  name: 'Bangkok',
  description: "Bangkok, la capitale de la Thaïlande, est une grande ville connue pour ses sanctuaires richement décorés et ses rues animées. Rempli de bateaux, le fleuve Chao Praya alimente son réseau de canaux et passe par le district royal de Rattanakosin, où se trouvent l'opulent Grand Palais et son temple sacré Wat Phra Kaeo. À proximité, le temple Wat Pho dispose d'un énorme Bouddha allongé et, sur la rive opposée, le temple Wat Arun est doté d'escaliers raides et d'une flèche de style Khmer.",
  images: {
    background: bangkok
  },
  stays: [
    gold,
    platinium
  ],
  comingsoon: true
}

export default city