import phuket from '../../../../assets/img/stays/thailand/phuket/city.jpg'

import platinium from './platinium'
import diamond from './diamond'

const city = {
  id: 'thailand-phuket',
  slug: 'thailand-phuket',
  name: 'Phuket',
  description: "La ville de Phuket, sur l'île de Phuket, est la capitale de la province thaïlandaise du même nom. Dans la vieille ville, la route de Thalang est bordée de \"shophouses\" colorées du XIXe siècle et de bâtiments sino-portugais. Construit en 1903 par un riche marchand d'étain, le manoir Baan Chinpracha possède du carrelage italien, des fenêtres à volets et du mobilier ancien. Le musée Thai Hua, installé dans un manoir des années 1930, abrite des expositions sur la culture et l'histoire de Phuket.",
  images: {
    background: phuket
  },
  stays: [
    platinium,
    diamond
  ],
  comingsoon: true
}

export default city